import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const PROMO_CODE_COLLECTION = 'promo-codes';

//TODO: Load through .env?
let firebaseConfig = {}
if (process.env.NODE_ENV === 'production') {
	firebaseConfig = {
		apiKey: "AIzaSyA3GRBJ2H-ISmWpLX0qjopIWfjDFh5vPwU",
		authDomain: "pickup-things.firebaseapp.com",
		databaseURL: "https://pickup-things.firebaseio.com",
		projectId: "pickup-things",
		storageBucket: "pickup-things.appspot.com",
		messagingSenderId: "635589512053",
		appId: "1:635589512053:web:72605b6b33b63ec642a13c",
		measurementId: "G-BDJ61N5TSX"
	}
}else {
	firebaseConfig = {
		apiKey: "AIzaSyCHqDJLUfl_URecTqIBZN15pdapE63Qz4M",
		authDomain: "unqueue-staging.firebaseapp.com",
		databaseURL: "https://unqueue-staging.firebaseio.com",
		projectId: "unqueue-staging",
		storageBucket: "unqueue-staging.appspot.com",
		messagingSenderId: "450380915657",
		appId: "1:450380915657:web:f33523d9c039874feafe18",
		measurementId: "G-6MRTXK86HP"
	}
}

if(!firebase.apps.length){
	firebase.initializeApp(firebaseConfig);
}

export default firebase;

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();

export const submitOrder = firebase.functions().httpsCallable('createOrder');

export const completeOrder = firebase.functions().httpsCallable('completeDraftOrder');

export const calculateDeliveryFee = firebase.functions().httpsCallable('calculateDeliveryFee');

export const signInWithEmail = (email, password) => auth.signInWithEmailAndPassword(email, password);

export const getPromoCode = (promoCode) => firestore.collection(PROMO_CODE_COLLECTION).where("code", "==", promoCode).get();

export const updatePromoCodeUses = (docId) => firestore.collection(PROMO_CODE_COLLECTION).doc(docId).update({uses: firebase.firestore.FieldValue.increment(1)})


