import '../styles/layout.css';
import GlobalContextProvider from '../context/GlobalContextProvider';
import { SnackbarProvider } from 'notistack';
import { init } from '../lib/sentry'
import { withStyles } from '@material-ui/core/styles';

init()

const styles = {
  root: {
    fontSize: 22,
    fontFamily: "PhantomSans"
  },
  containerRoot: {
    fontSize: 22,
    fontFamily: "PhantomSans"
  }
};

// This default export is required in a new `pages/_app.js` file.
const App = ({ Component, pageProps, classes}) => {
  return (
    <GlobalContextProvider>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        maxSnack={2}
        dense
        autoHideDuration={3000}
        classes={{
          root: classes.root,
          containerRoot: classes.containerRoot
        }}
      >

          <Component {...pageProps} />
      </SnackbarProvider>
    </GlobalContextProvider>
  )
}

export default withStyles(styles)(App);